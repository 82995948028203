import Axios from 'axios';

const env = process.env.NODE_ENV === 'development';
const CDN = 'https://vcm.sfo2.digitaloceanspaces.com/';
const API_URL = env ? 'http://localhost:8000/api/v1' : 'https://dev.api.caribemaya.com.mx/api/v1';
const API_BACK = env ? 'http://localhost:8000/api/' : 'https://dev.api.caribemaya.com.mx/api/';
export const axiosApi = Axios.create({
  baseURL: API_BACK,
});

const API_KEY = '5e1cd3ca4e5f23dbab0e9fc7b942fcb6';
const FLIGHTS_URL = 'https://api.aviationstack.com/v1/';

// NOTA checar los vuelos del dia http://api.aviationstack.com/v1/flights?access_key=f421579adf10a928188d302f87fd3f5a
export { API_URL, API_BACK, API_KEY, FLIGHTS_URL, CDN };
